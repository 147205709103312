
import React, { useEffect, useState, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux';
import Header from '../../components/generalComponents/header/Header';
import './Inicio.css'
import Footer from '../../components/generalComponents/footer/Footer';
import {
  fetchCategoriaList,
  categoriaSelectedChange,
  setRefCategory,
  setRefQuestion
} from '../../actions'
import GetMetaTags from '../../util/GetMetaTags';
import SocialLinks from '../../components/generalComponents/SocialLinks/SocialLinks';
import InfoSection from './infoSection/InfoSection';
import BannerSection from './bannerSection/BannerSection';
import CategoryListSection from './categoryListSection/CategoryListSection';
import FaqSection from './faqSection/FaqSection';
import OtherSection from './otherSection/OtherSection';

const Inicio = (props: any) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [admin, setAdmin] = useState<boolean>(false);
  const [categoriaList, setCategoriaList] = useState<any>(null);

  const questionSection = useRef<HTMLDivElement>(null);
  const refCategory = useRef<HTMLHeadingElement>(null);
  const [searchParams] = useSearchParams();

  const handleUrlParams = () => {
    let admin: any = searchParams.get("admin");
    if (admin) {
      setAdmin(true)
    }
  }

  useEffect(() => {
    if (admin && categoriaList) scrollToCategory()
  }, [admin, categoriaList])

  useEffect(() => {
    setCategoriaList(props.categorias)
  }, [props.categorias, props.userData])

  useEffect(() => {
    handleUrlParams()
    props.categoriaSelectedChange(-1)
  }, [])

  const handleScroll = () => {
    if(!admin) {
      const position = window.pageYOffset;
      setScrollPosition(position);
    }
  };

  useEffect(() => {
    props.fetchCategoriaList()
  }, [props.countryId])

  useEffect(() => {
    if (props.questionRef) {
      scrollToQuestion()
      props.setRefQuestion(false)
    }
    if (props.categoryRef) {
      scrollToCategory()
      props.setRefCategory(false)
    }
  }, [props.categoryRef, props.questionRef])

  const scrollToQuestion = () => {
    const element = questionSection.current;
    if (element) {
      const offset = 56; // Set the desired offset value

      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }

  const scrollToCategory = () => {
    const element = refCategory.current;
    if (element) {
      const offset = 56; // Set the desired offset value

      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  return (
    <div className="inicio-container">
      <GetMetaTags page={'inicio'}/>
      <Header home fixed trans={scrollPosition > 30} />
      {!admin && <BannerSection buttonPress={() => scrollToCategory()} />}
      <CategoryListSection
        refCategory={refCategory}
        categoriaSelectedChange={(data: any) => props.categoriaSelectedChange(data)}
        admin={admin}
        categoriaList={categoriaList}
      />
      <InfoSection/>
      <FaqSection 
        questionSection={questionSection} 
        admin={admin}
        categoriaSelectedChange={(data: any) => props.categoriaSelectedChange(data)}
      />
      {!admin && <OtherSection buttonPress={() => scrollToCategory()}/>}
      <SocialLinks/>
      {!admin && <Footer
        rif={props.questionRef}
        categoryReff={props.categoryRef}
      />}
    </div >
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    categorias: state.cotizar.categorias,
    categoryRef: state.general.categoryRef,
    questionRef: state.general.questionRef,
    userData: state.user.userData
  };
};

export default connect(mapStateToProps, {
  fetchCategoriaList,
  categoriaSelectedChange,
  setRefCategory,
  setRefQuestion
})(Inicio);