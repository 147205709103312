
import React, {useEffect} from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive'
import './CountrySelector.css'
import {
  changeCountryId
} from '../../actions'
import Logo from '../../assets/images/svgs/logoCountry.svg';
import { ReactSVG } from 'react-svg'
import { countries } from '../../data';
import { GetGlobalParams } from '../../util/GetGlobalParams';
import { USER_ACCESS_TOKEN, USER_DATA } from '../../actions/types';
import { store } from '../..';
import { GetCountryUrl } from '../../util/GetCountryUrl';

let isTabletOrMobile = false;
const CountrySelector = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
  const logoSize = isTabletOrMobile ? 85 : 95
  const {dispatch} = store

  const navigate = useNavigate()

  useEffect(() => {
    if(countries.length === 1) {
      props.changeCountryId(countries[0].id)
      dispatch({ type: USER_DATA, payload: null });
      dispatch({ type: USER_ACCESS_TOKEN, payload: null });
      navigate(GetCountryUrl(countries[0].id) + 'inicio')
    }
  }, [])

  const listItems = countries.map((country) => {
    const Logo = country.img;

    return (
      <Link
        className="countrySelector-container-countriesContainer-item"
        id= {`country_buttom_${country.id}`}
        to={GetCountryUrl(country.id) + 'inicio?'}
        onClick={() => {
          dispatch({ type: USER_DATA, payload: null })
          dispatch({ type: USER_ACCESS_TOKEN, payload: null });
        }}
      >
        <div className="countrySelector-container-countriesContainer-item-countryWrapper">
          <Logo />
        </div>
        <p className="countrySelector-container-countriesContainer-item-text">{country.title}</p>
      </Link>
    )
  });

  return (
    <div className="countrySelector-container">
      <ReactSVG 
        src={Logo}
        beforeInjection={svg => {
          svg.setAttribute('style', 'height: ' + logoSize);
          svg.setAttribute('style', 'width: ' + logoSize);
        }}
      />
      <div className="countrySelector-container-countriesContainer">
        {listItems}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    channelId: state.general.channelId
  };
};

export default connect(mapStateToProps, {
  changeCountryId
})(CountrySelector);