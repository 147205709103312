import React, { useEffect, useRef, useState } from 'react'
import './Oferta.css'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../components/generalComponents/header/Header';
import Footer from '../../components/generalComponents/footer/Footer';
import { 
  categoriaToOffer,
  postGenericForm,
  checkOutOffer,
  getRestrictions,
  getOffer,
  categoriaSelectedChange
} from '../../actions';
import { connect } from 'react-redux';
import OfferPlan from './OfferPlan/OfferPlan';
import AccordionCotizar from '../../components/mainCotizarComponents/AccordionCotizar/AccordionCotizar';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import ContactModal from '../../components/modalComponents/ContactModal/ContactModal';
import VerMasModal from '../../components/modalComponents/VerMasModal/VerMasModal';
import {store} from '../../index';

let isTabletOrMobile;
const Oferta = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })

  const navigate = useNavigate();

  const [verMasAlert, setVerMasAlert] = useState<boolean>(false);
  const [verMasInfo, setVerMasInfo] = useState<any>(null);
  const [contactAlert, setContactAlert] = useState<boolean>(false);
  const [contactSuccess, setContactSuccess] = useState<boolean>(false);
  const [Title, setTitle] = useState<string>('');
  const [subTitle, setSubTitle] = useState<string>('');
  const [contactSubTitle, setContactSubTitle] = useState<string>('');
  const [productId, setProductId] = useState<string>('');
  const [reCaptcha, setReCaptcha] = useState(null)
  const recaptchaRef = useRef();
  const [searchParams] = useSearchParams();
  const [admin, setAdmin] = useState<boolean>(false);

  const countryId = store.getState().general.countryId

  const handleUrlParams = () => {
    const productId = searchParams.get('productId');
    const offer = searchParams.get('offer');
    const cId = searchParams.get('cId');
    let admin: any = searchParams.get("admin")
    if (admin) {
      setAdmin(true)
    }
    if (productId) {
      setProductId(productId)
      let title: any, subTitle: any;
      switch (productId) {
        case "9242":
          title = 'Salud Integral';
          subTitle = 'Precios transparentes, coberturas ideales para personas entre 18 y 65 años';
          break;
        case "9243":
          title = 'Salud Mujer';
          subTitle = 'Precios transparentes, coberturas ideales para mujeres entre 18 y 65 años';
          break;
        case "9244":
          title = 'Salud Senior';
          subTitle = 'Precios transparentes, coberturas ideales para adultos mayores entre 50 y 70 años';
          break;
        default:
          title = 'Salud Integral';
          subTitle = 'Precios transparentes, coberturas ideales para personas entre 18 y 65 años';
          break;
      }
      props.categoriaToOffer(productId);
      setTitle(title);
      setSubTitle(subTitle);
    }
    if(cId && offer) {
      props.categoriaSelectedChange(cId)
      if(!props.offerData) {
          props.getOffer(cId, offer, null)
      }
    }
  };

  useEffect(() => {
    handleUrlParams()
  }, [])

  const verMasModal = () => {
    return (
      <Modal
        isOpen={verMasAlert}
        onRequestClose={() => setVerMasAlert(false)}
        style={{
          content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '25px',
              width: isTabletOrMobile ? '80%' : '50%',
              padding: '20px'
          },
          overlay: { backgroundColor: 'rgba(255, 255, 255, 0.5)' }
        }}
      >
        <VerMasModal
          info={verMasInfo}
          close={() => setVerMasAlert(false)}
        />
      </Modal>
    )
  }

  const contactModal = () => {
    return (
      <Modal
        isOpen={contactAlert}
        onRequestClose={() => setContactAlert(false)}
        style={{
          content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '25px',
              width: isTabletOrMobile ? '80%' : 'unset',
          },
          overlay: { backgroundColor: 'rgba(255, 255, 255, 0.5)' }
        }}
      >
        <ContactModal
          title={Title}
          subTitle={contactSubTitle}
          recaptchaRef={recaptchaRef}
          reCaptcha={reCaptcha}
          setReCaptcha={setReCaptcha}
          close={() => setContactAlert(false)}
          success={contactSuccess}
          sendContact={(data: any) => props.postGenericForm('fast_contact', data, reCaptcha, recaptchaRef, setReCaptcha, navigate, 'contacto-exito', countryId, setContactSuccess)}
        />
      </Modal>
    )
  }

  return (
    <div className="oferta-container">
        {!admin && <Header home colored special />}
        <div className="oferta-container-main">
            <h4 className="oferta-container-main-title bold">{Title}</h4>
            <b className="oferta-container-main-subTitle bold regular large">{subTitle}</b>
            <div className="oferta-container-main-planList">
              {props.offerData?.packages?.map((a: any, index: any) => (
                <OfferPlan
                  key={index}
                  index={index}
                  title={a.description}
                  score={a.assessment}
                  planType={a.planType}
                  unit='$'
                  price={a.price?.formattedValue?.replace(/\$/g, '')}
                  per='/mes'
                  queCubre={a.coverageGroups[0]?.coverages}
                  seeMore={() => props.getRestrictions(props.categoriaSelected, props.offerData?.id, props.offerData?.packages[0]?.id, setVerMasAlert, setVerMasInfo)}
                  contratar={() => {
                    if(props.userData?.privileges?.filter(function(e) { return e === 'insurance_policies:create' }).length > 0 && admin) {
                      const adminN = admin ? '&admin=True' : '';
                      navigate('/user-select?cId=' + props.offerData?.product?.categoryId + '&offerId=' + props.offerData?.id + '&packageId=' + a.id + adminN)
                    } else {
                      props.checkOutOffer(props.offerData?.id, props.offerData?.product?.categoryId, a.id, navigate, props.countryId, null, admin)
                    }
                  }}
                  contact={() => {
                    setContactSubTitle(a.description)
                    setContactAlert(true)
                    setContactSuccess(false)
                  }}
                />
              ))}
            </div>
            {props.offerData?.packages[0]?.conditions?.length !== 0 && <h4 className="oferta-container-main-title bold">Me gustaría saber...</h4>}
            <div className="oferta-container-main-faqList">
              {props.offerData?.packages[0]?.conditions?.map((a: any, index: any) => (
                <AccordionCotizar
                  id={a.name + ' faq'}
                  key={index}
                  title={a.name}
                  text={a.description}
                />
              ))}
            </div>
        </div>
        {verMasModal()}
        {contactModal()}
      {!admin && <Footer/>}
    </div>
  )
}

const mapStateToProps = (state: any) => {
    return {
      countryId: state.general.countryId,
      offerData: state.mainCotizar.offerData,
      userData: state.user.userData,
      categoriaSelected: state.cotizar.categoriaSelected,
    };
};

export default connect(mapStateToProps, {
    categoriaToOffer,
    postGenericForm,
    checkOutOffer,
    getRestrictions,
    getOffer,
    categoriaSelectedChange
})(Oferta);