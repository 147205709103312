

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import Header from '../../components/generalComponents/header/Header';
import { useMediaQuery } from 'react-responsive'
import './Reclamo.css'
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { FormattedMessage } from 'react-intl';
import Footer from '../../components/generalComponents/footer/Footer';
import {
  getContactMethods,
  getClaimProcessById,
  putAnswerQuestion
} from '../../actions'
import ReclamoSteps from '../../components/reclamoComponents/ReclamoSteps/ReclamoSteps';
import Lottie from 'react-lottie';
import exitoPago2 from '../../assets/lotties/exitoPago2.json'
import { GetGlobalParams } from '../../util/GetGlobalParams';
import { GetCountryUrl } from '../../util/GetCountryUrl';
import GetGlobalForm from '../../components/generalComponents/GlobalForms/getGlobalForm';

let isTabletOrMobile = false;
const Reclamo = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' });
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>();
  const [currentForm, setCurrentForm] = useState<any>(null)
  const [currentStep, setCurrentStep] = useState<number>(-1)
  const [isLast, setIsLast] = useState<boolean>(false)

  useEffect(() => {
    props.getContactMethods()
    if (id) {
      props.getClaimProcessById(id)
    }
  }, [])

  useEffect(() => {
    const selectedForm = props.claimProcessData?.incompleteStep.forms.filter((a: any) => a.formId === props.claimProcessData?.incompleteStep.incompleteFormId)[0]
    selectedForm?.questions.sort((a: any, b: any) => a.order - b.order);
    setCurrentForm(selectedForm)
    setCurrentStep(selectedForm?.order)
    const aux = {...props.claimProcessData}
    aux.incompleteStep?.forms.sort((a: any, b: any) => a.order - b.order);
    const lastStep = selectedForm?.formId === aux.incompleteStep?.forms[aux.incompleteStep?.forms.length - 1].formId && props.claimProcessData?.incompleteStep.lastStep
    setIsLast(lastStep)
  }, [props.claimProcessData])
  
  const isDisabled = () => {
    let control = false
    currentForm?.questions.map((a: any) => {
      if(a.required) {
        if(props.globalFormAnswer[a.id]) {
          if(Array.isArray(props.globalFormAnswer[a.id])) {
            if(props.globalFormAnswer[a.id].filter(b => b !== '').length < a.input.presignedUrl.length) {
              control = true
            }
          }
        } else {
          control = true
        }
      }
    })
    return control
  }

  const endDataFinal = () => {
    return (
      <React.Fragment>
        {props.claimProcessData?.result?.policyNumber && <p className="reclamo-containerFinal-finalMain-text3"><FormattedMessage id = "page.reclamo.exito.text1" defaultMessage="Tu número de reclamo es el {number}" values={{number: '#' + props.claimProcessData?.result?.policyNumber}}/></p>}
        <p className="reclamo-containerFinal-finalMain-text4"><FormattedMessage id = "page.reclamo.exito.text2" defaultMessage="Podés acceder cuando quieras ingresando a Mis reclamos. Te mantendremos al tanto sobre el estado de tu reclamo y, en caso que sea necesario, te pediremos la documentación faltante."/></p>
        <div className="reclamo-containerFinal-finalMain-buttonWrapper">
          <MainButton
            id='Continuar Boton'
            text={'ver reclamo'.toUpperCase()}
            onButtonPressed={() => navigate(GetCountryUrl(props.countryId) + 'policy/' + props.claimProcessData?.result.productInsuredUserId)}
            altStyle3={!isTabletOrMobile}
          />
        </div>
        <div className="reclamo-containerFinal-finalMain-buttonWrapper">
          <MainButton
            id='Continuar Boton'
            text={'volver al home'.toUpperCase()}
            onButtonPressed={() => {
              if(props.userAccessToken) {
                navigate(GetCountryUrl(props.countryId) + 'home')
              } else {
                navigate(GetCountryUrl(props.countryId) + 'inicio')
              }
            }}
            altStyle4={!isTabletOrMobile}
            altStyle2={isTabletOrMobile}
          />
        </div>
      </React.Fragment>
    )
  }

  return (
    <div className={props.claimProcessData?.status === "COMPLETED" ? "reclamo-containerFinal" : "reclamo-container"}>
      {props.claimProcessData?.status === "COMPLETED" ?
        <React.Fragment>
          <Header colored/>
          <div className="reclamo-containerFinal-finalMain">
            <Lottie
              options={{
                loop: true,
                autoplay: true, 
                animationData: exitoPago2,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={100}
              width={100}
              isStopped={false}
              isPaused={false}
            />
            <p className="reclamo-containerFinal-finalMain-text1"><FormattedMessage id = "page.reclamo.exito.text3" defaultMessage="¡Muchas gracias {name}!" values={{name: props.userData?.name}}/></p>
            <p className="reclamo-containerFinal-finalMain-text2"><FormattedMessage id = "page.reclamo.exito.text4" defaultMessage="Tu reclamo fue realizado con éxito"/></p>
            {isTabletOrMobile ? 
              <div className="reclamo-containerFinal-finalMain-ResContainer">
                {endDataFinal()}
              </div>
              :
              <React.Fragment>
                {endDataFinal()}
              </React.Fragment>
            }
          </div>
        </React.Fragment>
        :
        <React.Fragment>
          <Header backgroundColor={isTabletOrMobile ? "var(--white)" : null}/>
          <div className="reclamo-container-topContainer">
            <p className="reclamo-container-topContainer-text"><FormattedMessage id = "page.reclamo.exito.text5" defaultMessage="Nuevo Reclamo"/></p>
            <div
              className="reclamo-container-topContainer-stepContainer"
              style={{ display: props.claimProcessData?.incompleteStep.forms.length > 1 ? 'flex' : 'none' }}
            >
              <ReclamoSteps
                stepList={props.claimProcessData?.incompleteStep.forms}
                currentStep={currentStep}
              />
            </div>
          </div>
          <div className="reclamo-container-mask">
            <div className="reclamo-container-mask-inner"></div>
          </div>
          <div className="reclamo-container-main">
            {props.claimProcessData?.incompleteStep.forms.length === 1 &&
              <React.Fragment>
                <p className="reclamo-container-main-title">{currentForm?.name}</p>
                <p className="reclamo-container-main-description">{currentForm?.description}</p>
              </React.Fragment>
            }
            <div 
              className="reclamo-container-main-formContainer"
              style={{
                flexDirection: currentForm?.config?.columns === '1' || isTabletOrMobile ? 'column' : 'row'
              }}
            >
              {currentForm?.questions.map((a: any, index: any) => (
                <div 
                  key={index}
                  className="reclamo-container-main-formContainer-itemForm"
                  style={{
                    width: currentForm?.config?.columns === '1' || isTabletOrMobile ? '100%' : '32.5%',
                    marginTop: currentForm?.config?.columns === '1' || isTabletOrMobile ? 10 : 0,
                    marginBottom: currentForm?.config?.columns === '1' || isTabletOrMobile ? 10 : 0,
                  }}
                >
                  <GetGlobalForm
                    item={a}
                    currentAnswer={props.globalFormAnswer} 
                    next={null} 
                    setCurrentAnswer={null}
                  />
                </div>
              ))}
            </div>
            <div className="reclamo-container-main-buttonWrapper">
              <MainButton
                id='Continuar Boton'
                text={isLast ? 'finalizar'.toUpperCase() : 'continuar'.toUpperCase()}
                disabled={isDisabled()}
                onButtonPressed={() => props.putAnswerQuestion(props.globalFormAnswer, props.claimProcessData?.id, props.claimProcessData?.incompleteStep.id, props.claimProcessData?.incompleteStep.incompleteFormId)}
              />
            </div>
          </div>
          <p className="reclamo-container-contacto"><FormattedMessage id="title.ayuda.whatsapp" defaultMessage=""/> <a href={'https://wa.me/' + props.contactMethods?.filter(a => a.id === "whatsapp")[0].config.telephone}>Contactate por Whatsapp</a></p>
          <Footer/>
        </React.Fragment>
      }
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    contactMethods: state.mainCotizar.contactMethods,
    claimProcessData: state.reclamo.claimProcessData,
    userData: state.user.userData,
    userAccessToken: state.general.userAccessToken,
    globalFormAnswer: state.general.globalFormAnswer
  };
};

export default connect(mapStateToProps, {
  getContactMethods,
  getClaimProcessById,
  putAnswerQuestion
})(Reclamo);