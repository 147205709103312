import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { GetCountryUrl } from "../../util/GetCountryUrl";
import { GetGlobalParams } from "../../util/GetGlobalParams";
import { countries } from '../../data';

const RouterInterceptor = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [currentCountry, setCurrentCountry] = useState<number | undefined>(undefined);

  const { changeCountryId, startCountry, setCountry } = props;

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

  React.useEffect(() => {

    /**
     * A partir del queryparam retorna el identificador número del páis
     * @param search Query params de la url
     * @returns retorna el pais, si esta presente
     */
    const getCountryParamValue = (search: string, countryUrl: string): number | undefined => {
      const params: URLSearchParams = new URLSearchParams(search);

      let globalParam: string = '';
      params.forEach((value, key) => {
        if(key.includes('utm_') || key.includes('wtm_')) {
          if(globalParam === '') {
            globalParam = globalParam.concat(key + '=' + value)
          } else {
            globalParam = globalParam.concat('&' + key + '=' + value)
          }
        }
      });
      props.changeGlobalParams(globalParam)

      if(countries.length === 1) {
        return countries[0].id
      } else {
        if (!countryUrl || countryUrl !== '') {
          let countryParam: any = countries.find(c => c.url === countryUrl)?.id
  
          if (!isNaN(parseInt(countryParam))) {
            return parseInt(countryParam);
          }
        }
      }
    }

    /**
     * Función que se ejecuta cuando cambia la URL de la aplicación.
     * @param search Query Params
     */
    const changeLocation = (search: string, countryUrl: string) => {
      let countryId: number | undefined = getCountryParamValue(search, countryUrl);

      if (countryId === currentCountry) {
        setCountry(countryId)
        return;
      }

      if (countryId === undefined) {
        new Cookies().remove("countryId", countryId);
        changeCountryId(undefined);
        return;
      }     

      setCurrentCountry(countryId);

      if (countryId !== undefined) {
        const cookies = new Cookies();
        const cookieCountry = parseInt(cookies.get('countryId'));
        if(isNaN(cookieCountry)) {
          cookies.set("countryId", countryId);
          changeCountryId(countryId);
          return;
        }
        if (cookieCountry && cookieCountry !== countryId) {
          changeCountryId(countryId);
          return;
        } else {
          setCountry(countryId)
        } 
      }

      startCountry(countryId)
    }

    changeLocation(location.search, location.pathname.substring(1,4));
    scrollToTop();
  }, [location]);

  return <React.Fragment />
}



export default RouterInterceptor;