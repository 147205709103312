import {
  LOADING,
  USER_ACCESS_TOKEN,
  USER_DATA,
  EMAIL_VALIDATE,
  CHECKOUT_DATA,
  USER_ACCESS_TOKEN_AUX,
  SUCCESS_CARD_TEXT,
  RECOVERY_PASSWORD_ID
} from './types';
import {
  getBasic,
  loginUserAuth,
  getUserMine,
  sendCodeValidate,
  getCodeValidate,
  createUser,
  netCheckOutPolicyHolder,
  getIdRecoveryPassword,
  postSuccessCodeRecoveryEmail,
  postSuccessNewPassword
} from '../net/Connector'
import axios from './instanceActions'
import axiosAlt from './instanceLoginActions'
import { genericErrorHandler } from '../util/GenericErrorHandler';
import { GetGlobalParams } from '../util/GetGlobalParams';
import { GetCountryUrl } from '../util/GetCountryUrl';
import id from 'date-fns/esm/locale/id/index.js';

export const loginGoogleUser = (googleToken: any, countryId: any, checkoutId: any, login: boolean, url: string, navigate: any, setHasToLogin: any) => {
  return (dispatch: any) => {
    dispatch({ type: LOADING, payload: true });
    axios({
      method: 'post',
      url: loginUserAuth(),
      params: {
        googleToken,
        countryId: countryId.toString()
      },
      headers: {
        Authorization: getBasic(),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((responseAuth) => {
        dispatch({ type: USER_ACCESS_TOKEN, payload: 'Bearer ' + responseAuth.data.access_token })
        axios.get(getUserMine(), { params: { countryId: countryId } })
          .then((response) => {
            const user = response.data
            dispatch({ type: USER_DATA, payload: user });
            if (login) {
              if (url) {
                navigate(url)
              } else {
                navigate(GetCountryUrl(countryId) + 'home')
              }
            } else {
              axios({
                url: netCheckOutPolicyHolder(checkoutId, user.id),
                method: 'put',
                headers: {
                  'accept': '*/*',
                  'Content-Type': 'application/json',
                },
              })
                .then((response) => {
                  dispatch({ type: CHECKOUT_DATA, payload: response.data });
                  setHasToLogin(false)
                  dispatch({ type: LOADING, payload: false });
                })
                .catch((error) => {
                  dispatch({ type: LOADING, payload: false });
                  genericErrorHandler(error)
                })
            }
          })
          .catch((error) => {
            dispatch({ type: LOADING, payload: false });
            genericErrorHandler(error)
          });
      })
      .catch((error) => {
        dispatch({ type: LOADING, payload: false });
        genericErrorHandler(error)
      });
  };
};

export const loginUser = (email: string, password: string, login: boolean, countryId: any, url: string, checkoutId: any, navigate: any, setHasToLogin: any) => {
  return (dispatch: any) => {
    dispatch({ type: LOADING, payload: true });
    axiosAlt({ method: 'post', url: loginUserAuth(), params: { username: email, password, grant_type: 'password' }, headers: { Authorization: getBasic(), 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then((responseAuth) => {
        dispatch({ type: USER_ACCESS_TOKEN, payload: 'Bearer ' + responseAuth.data.access_token })
        axios.get(getUserMine(), { params: { countryId: countryId } })
          .then((response) => {
            const user = response.data
            dispatch({ type: USER_DATA, payload: user });
            if (user.emailValidated) {
              if (login) {
                if (url) {
                  navigate(url)
                } else {
                  navigate(GetCountryUrl(countryId) + 'home')
                }
              } else {
                axios({
                  url: netCheckOutPolicyHolder(checkoutId, user.id),
                  method: 'put',
                  headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                  },
                })
                  .then((response) => {
                    dispatch({ type: CHECKOUT_DATA, payload: response.data });
                    setHasToLogin(false)
                    dispatch({ type: LOADING, payload: false });
                  })
                  .catch((error) => {
                    dispatch({ type: LOADING, payload: false });
                    genericErrorHandler(error)
                  })
              }
            } else {
              dispatch({ type: USER_ACCESS_TOKEN, payload: null })
              dispatch({ type: USER_ACCESS_TOKEN_AUX, payload: 'Bearer ' + responseAuth.data.access_token })
              dispatch({ type: EMAIL_VALIDATE, payload: email });
              navigate(GetCountryUrl(countryId) + 'home', { state: { login: 'InicioSesion' } })
              dispatch({ type: LOADING, payload: false });
            }
          })
          .catch((error) => {
            dispatch({ type: LOADING, payload: false });
            dispatch({ type: USER_ACCESS_TOKEN, payload: null })
            genericErrorHandler(error)
          });
      })
      .catch((error) => {
        dispatch({ type: LOADING, payload: false });
        genericErrorHandler(error)
      });
  };
};

export const setAccessTokenAux = (value: any) => {
  return {
    type: USER_ACCESS_TOKEN_AUX,
    payload: value
  }
}

export const registerUser = (name: string, lastName: string, email: string, password: string, countryId: number, originalParams: any, navigate: any) => {
  return (dispatch: any) => {
    dispatch({ type: LOADING, payload: true });
    axios({
      method: 'post',
      url: createUser(),
      headers: {
        Authorization: getBasic(),
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Context': btoa(GetGlobalParams())
      },
      data: `{ \"email\": \"${email}\", \"lastName\": \"${lastName}\", \"name\": \"${name}\", \"password\": \"${password}\" }`
    })
      .then(responseCreate => {
        axiosAlt({ method: 'post', url: loginUserAuth(), params: { username: email, password, grant_type: 'password' }, headers: { Authorization: getBasic(), 'Content-Type': 'application/x-www-form-urlencoded' } })
          .then((responseAuth) => {
            dispatch({ type: USER_ACCESS_TOKEN, payload: 'Bearer ' + responseAuth.data.access_token })
            dispatch({ type: USER_ACCESS_TOKEN_AUX, payload: 'Bearer ' + responseAuth.data.access_token })
            axios.get(getUserMine(), { params: { countryId: countryId } })
              .then((response) => {
                const user = response.data
                dispatch({ type: USER_ACCESS_TOKEN, payload: null });
                dispatch({ type: EMAIL_VALIDATE, payload: email });
                dispatch({ type: USER_DATA, payload: user });
                navigate(GetCountryUrl(countryId) + 'verificacion' + originalParams + '&')
                dispatch({ type: LOADING, payload: false });
              })
              .catch((error) => {
                dispatch({ type: LOADING, payload: false });
                genericErrorHandler(error)
              })
          })
          .catch((error) => {
            dispatch({ type: LOADING, payload: false });
            genericErrorHandler(error)
          })
      })
      .catch(error => {
        dispatch({ type: LOADING, payload: false });
        genericErrorHandler(error)
      });
  }
}

export const resendEmailValidate = (userAccessTokenAux: string) => {
  return (dispatch: any) => {
    dispatch({ type: LOADING, payload: true });
    dispatch({ type: SUCCESS_CARD_TEXT, payload: { text: '', state: false } });
    axios({
      method: 'post',
      url: getCodeValidate(),
      headers: {
        'Authorization': userAccessTokenAux
      }
    })
      .then((response) => {
        dispatch({ type: LOADING, payload: false });
        dispatch({ type: SUCCESS_CARD_TEXT, payload: { text: 'Enviamos el código nuevamente.', state: true } });
      })
      .catch((error) => {
        dispatch({ type: SUCCESS_CARD_TEXT, payload: { text: '', state: false } });
        dispatch({ type: LOADING, payload: false });
        genericErrorHandler(error)
      })
  }
}

export const toggleOffCodeResendSuccess = (text: string) => {
  return {
    type: SUCCESS_CARD_TEXT,
    payload: { text: text, state: false }
  }
}

export const getUserDataByAux = (token: any, countryId: any) => {
  return (dispatch: any) => {
    axiosAlt.get(getUserMine(), { headers: { 'Authorization': token }, params: { countryId: countryId } })
      .then((response) => {
        dispatch({ type: USER_DATA, payload: response.data });
        dispatch({ type: EMAIL_VALIDATE, payload: response.data.username });
      })
      .catch((error) => {
      })
  }
}

export const recoveryPassReciveId = (userName: string, countryId: number, redirectTo: string, navigate: any) => {
  return async (dispatch: any) => {
    dispatch({ type: LOADING, payload: true });
    try {
      const response = await axios.post(getIdRecoveryPassword(), { username: userName })
      dispatch({ type: RECOVERY_PASSWORD_ID, payload: response.data.id });
      if (redirectTo !== '') {
        navigate(GetCountryUrl(countryId) + 'recovery-code?redirectTo=' + redirectTo, { state: { userName: userName, link: redirectTo } })
      } else {
        navigate(GetCountryUrl(countryId) + 'recovery-code', { state: { userName: userName } })
      }
    } catch (error) {
      genericErrorHandler(error);
    } finally {
      dispatch({ type: LOADING, payload: false });
    }
  };
}

export const recoverySuccessCode = (id: string, count: number, code: string, redirectTo: string, navigate: any) => {
  return async (dispatch: any) => {
    dispatch({ type: LOADING, payload: true });
    try {
      await axios.put(postSuccessCodeRecoveryEmail(id), { code: code });
      if (redirectTo !== '') {
        navigate(GetCountryUrl(count) + 'recovery-code-form?redirectTo=' + redirectTo, { state: { id: id, link: redirectTo } })
      } else {
        navigate(GetCountryUrl(count) + 'recovery-code-form', { state: { id: id } })
      }

    } catch (error) {
      genericErrorHandler(error);
    } finally {
      dispatch({ type: LOADING, payload: false });
    }
  }
}

export const resendEmailCode = (userName: string) => {
  return async (dispatch: any) => {
    dispatch({ type: LOADING, payload: true });
    dispatch({ type: SUCCESS_CARD_TEXT, payload: { text: '', state: false } });
    try {
      const response = await axios.post(getIdRecoveryPassword(), { username: userName });
      dispatch({ type: RECOVERY_PASSWORD_ID, payload: response.data.id });
      dispatch({ type: SUCCESS_CARD_TEXT, payload: { text: 'Enviamos el código nuevamente.', state: true } });
    } catch (error) {
      genericErrorHandler(error);
    } finally {
      dispatch({ type: LOADING, payload: false });
    }
  }
}

export const recoverySuccessNewPassword = (id: string, password: string, countryId: number, redirectTo: string, navigate: any) => {
  return async (dispatch: any) => {
    dispatch({ type: LOADING, payload: true });
    try {
      await axios.put(postSuccessNewPassword(id), { password: password });
      if (redirectTo !== '') {
        navigate(GetCountryUrl(countryId) + 'recovery-code-form-finish?redirectTo=' + redirectTo, { state: { link: redirectTo } })
      } else {
        navigate(GetCountryUrl(countryId) + 'recovery-code-form-finish')
      }
    } catch (error) {
      genericErrorHandler(error);
    } finally {
      dispatch({ type: LOADING, payload: false });
    }
  }
}