import Logo from '../../assets/images/svgs/logo.svg';
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import './AdminInicio.css'
import CustomTextInput from "../../components/generalComponents/customTextInput/CustomTextInput";
import MainButton from "../../components/generalComponents/MainButton/MainButton";
import { connect } from 'react-redux';
import {
    loginGoogleUser,
    loginUser
} from '../../actions';
import { useNavigate, useLocation } from 'react-router-dom'
import { GetGlobalParams } from "../../util/GetGlobalParams";
import { GetCountryUrl } from "../../util/GetCountryUrl";
import { validateEmail } from "../../util/ValidateEmail";
import GetMetaTags from "../../util/GetMetaTags";

let isTabletOrMobile = false;
const AdminInicio = (props: any) => {
    isTabletOrMobile = useMediaQuery({ query: '(max-width: 37.5em)' })
    const [emailLogin, setEmailLogin] = useState<string>('');
    const [contraseñaLogin, setContraseñaLogin] = useState<string>('');
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const urlState: string = query.get('redirectTo')!;

    const disableLogin = () => {
        return !(emailLogin !== '' && contraseñaLogin !== '')
    }

    return (
        <div className="admin-container">
            <GetMetaTags page={'login'} />
            <div className='admin-container-main'>
                <img src={Logo} className='admin-container-main-img'/>
                <h2 className="admin-container-main-title bold">Te damos la bienvenida a tu administrador</h2>
                <h4 className="admin-container-main-subtitle regular">Iniciá sesión</h4>
                <div className="admin-container-main-inputs">
                    <CustomTextInput
                        adminLogin
                        label='Email'
                        placeholder='nombre@ejemplo.com'
                        changeText={(e: string) => setEmailLogin(e)}
                        error={emailLogin.length > 0 ? !validateEmail(emailLogin) : false}
                        value={emailLogin}
                    />
                    <CustomTextInput
                        adminLogin
                        label='Contraseña'
                        placeholder='*********'
                        changeText={(e: string) => setContraseñaLogin(e)}
                        value={contraseñaLogin}
                        password
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                if (!disableLogin()) {
                                    props.loginUser(emailLogin, contraseñaLogin, true, props.countryId, urlState, null, navigate, null)
                                }
                            }
                        }}
                    />
                </div>
                <b className="admin-container-main-pass bold" onClick={() => navigate(GetCountryUrl(props.countryId) + 'recovery')}>¿Olvidaste tu contraseña?</b>
                <div className="admin-container-main-button">
                    <MainButton
                        id='INICIAR SESIÓN'
                        disabled={disableLogin()}
                        text='INICIAR SESIÓN'
                        onButtonPressed={() => props.loginUser(emailLogin, contraseñaLogin, true, props.countryId, urlState, null, navigate, null)}
                        fill
                        color='var(--white)'
                        textColor='var(--primary500)'
                    />
                </div>
                <div className="admin-container-main-marcador">
                    <span className="material-symbols-outlined" style={{fontSize: 48}} >{!isTabletOrMobile ? 'bookmark' : 'add_to_home_screen'}</span>
                    <div className="admin-container-main-marcador-text">
                        <p className="admin-container-main-marcador-text-title bold">{isTabletOrMobile ? 'ACCESO DIRECTO' : 'MARCADORES'}</p>
                        <b className="admin-container-main-marcador-text-subTitle regular medium"> {
                            isTabletOrMobile ? 
                                'Recordá que desde tu navegador, podés agregar este sitio a la pantalla principal de tu cel y acceder cuando quieras.'
                                :
                                'Recordá que desde tu navegador, podés agregar este sitio a marcadores y acceder cuando quieras.'
                        }</b>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        countryId: state.general.countryId,
    };
};

export default connect(mapStateToProps, {
    loginGoogleUser,
    loginUser
})(AdminInicio);