import React, {useState} from 'react'
import CustomTextInput from '../../../../customTextInput/CustomTextInput';
import './FormFieldNumberStyleOne.css'

const FormFieldNumberStyleOne = (props: any) => {

    const [localText, setLocalText] = useState<string>('')

    return (
        <div className="FormFieldNumberStyleOne-inputContainer">
            <h1 dangerouslySetInnerHTML={{__html: props.data?.display?.name}}/>
            <p className="FormFieldNumberStyleOne-inputContainer-description" dangerouslySetInnerHTML={{__html: props.data?.display?.description}}/>
            <div className="FormFieldNumberStyleOne-inputContainer-wrapper">
                <CustomTextInput
                    label=''
                    placeholder={props.data?.display?.placeholder}
                    changeText={(e: string) => {
                        const res = e.replace(/\D/g,'')
                        setLocalText(res)
                        props.onUpdate(res)
                    }}
                    value={localText}
                />
            </div>
        </div>
    );
}

export default FormFieldNumberStyleOne;