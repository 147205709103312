import React, { useEffect, useState } from 'react'
import './SearchInputComponent.css'

const SearchInputComponent = (props: any) => {

  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [inputApplied, setInputApplied] = useState<boolean>(false);

  useEffect(() => {
    if(props.applied) setInputApplied(props.applied)
  }, [props.applied])

  return (
    <div className={inputFocus ? "searchInputComponent-inputContainerFocus" : "searchInputComponent-inputContainer"}>
      <input
        className="searchInputComponent-inputContainer-input"
        placeholder='Buscá por nro de cotización'
        value={props.input}
        onChange={(e) => props.setInput(e.target.value.replace(/[^0-9]+/g, ''))}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
      />
      <div 
        className="searchInputComponent-inputContainer-icon"
        onClick={() => props.input === '' ? null : props.handleSearchButton() }
        style={{ opacity: props.input === '' ? 0.6 : 1, cursor: props.input === '' ? 'auto' : 'pointer' }}
      >
        <span className="material-symbols-outlined" style={{ fontSize: 20 }}>{inputApplied ? 'close' : 'search'}</span>
      </div>
    </div>
  );
  
}

export default SearchInputComponent;