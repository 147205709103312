import React from 'react'
import './NumCotComponent.css'

const NumCotComponent = (props: any) => {

  return (
    <div className="numCotComponent">
        <b className="numCotComponent-text regular medium">{props.text}</b>
        <span 
            className="material-symbols-outlined" 
            style={{ color: 'var(--primary500)', fontSize: 20, cursor: 'pointer'}}
            onClick={() => props.closeClick()}
        >close</span>
    </div>
  )
}

export default NumCotComponent;