import Candado from '../../../assets/lotties/candado.json';
import Lottie from 'react-lottie';
import { useEffect } from 'react';
import Header from '../../../components/generalComponents/header/Header';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { setAccessTokenAux, userAccessTokenSet } from '../../../actions';
import { GetGlobalParams } from '../../../util/GetGlobalParams';
import { GetCountryUrl } from '../../../util/GetCountryUrl';

const ChangePasswordSuccess = (props: any) => {

  const dispatch = useDispatch();

  const navigate = useNavigate()

  useEffect(() => {
    const timer1= setTimeout(() => {
      props.userAccessTokenSet(null);
      dispatch(setAccessTokenAux(null));
      dispatch({ type: 'USER_DATA', payload: null });
      navigate(GetCountryUrl(props.countryId) + 'login');
    }, 3800);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <div className="changePassword-container">
      <Header admin />
      <div className="changePassword-container-success">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: Candado,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={200}
          width={200}
          isStopped={false}
          isPaused={false}
        />
        <p className="changePassword-container-success-title"><FormattedMessage id="page.changePassword.success.title" defaultMessage="¡Listo!" /></p>
        <p className="changePassword-container-success-text"><FormattedMessage id="page.changePassword.success.text" defaultMessage="Tu contraseña se cambió correctamente. Te redirigiremos al inicio de sesión para ingresar con tu nueva contraseña." /></p>
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
  };
};

export default connect(mapStateToProps, { userAccessTokenSet })(ChangePasswordSuccess);
