import {
    OFFER_DATA,
    ORIGINAL_OFFER_DATA,
    LOADING,
    SHOW_RESTRICTIONS,
    RESTRICTIONS_TEXT,
    CONTACT_METHODS,
    CHECKOUT_DATA,
    CONTACT_WHATSAPP
} from './types';
import {
    netGetOffer,
    netModifyOffer,
    netModifyOfferDate,
    netGetRestrictions,
    netGetContactMethods,
    netCheckOutOffer,
    netSelectPackage,
    netGetContactMethodsWhatsapp,
    netCheckOutPolicyHolder,
    netModifyOfferByPackage
} from '../net/Connector'
import axios from './instanceActions'
import { genericErrorHandler } from '../util/GenericErrorHandler';
import { GetGlobalParams } from '../util/GetGlobalParams';
import { GetCountryUrl } from '../util/GetCountryUrl';

export const getOffer = (categoryId: Number, offer: String, setLoadingOffer: any) => {
  return (dispatch: any) => {
    dispatch({type: LOADING, payload: true});
    axios.get(netGetOffer(categoryId, offer))
      .then((response) => {
        if (response?.data?.status === "CREATING") {
          dispatch({type: LOADING, payload: false});
          setLoadingOffer(true)
          const offerStatus = () => {
            axios.get(netGetOffer(categoryId, offer))
              .then((response) => {
                if (response?.data?.status !== "CREATING") {
                  dispatch({type: OFFER_DATA, payload: response.data});
                  setLoadingOffer(false)
                }
              })
              .catch((error) => {
                genericErrorHandler(error)
                clearInterval(refreshIntervalId);
                setLoadingOffer(false)
              })
          }
          var refreshIntervalId = setInterval(offerStatus, 3000);
        } else {
          dispatch({type: OFFER_DATA, payload: response.data});
          dispatch({type: LOADING, payload: false});
        }
      })
      .catch((error) => {
        dispatch({type: LOADING, payload: false});
        genericErrorHandler(error)
        setLoadingOffer(false)
      })
  }
}

export const modifyOfferByPackage = (categoriaId: Number, offerId: Number, packageId: Number, coverageId: Number, value: any) => {
  return (dispatch: any) => {
    dispatch({type: LOADING, payload: true});
    axios({
      url: netModifyOfferByPackage(categoriaId, offerId, packageId, coverageId),
      method: 'patch',
      headers: {
          'accept': '*/*',
          'Content-Type': 'application/json',
      },
      data: `{\"value\": ${value}}`
    })
      .then((response) => {
        dispatch({type: OFFER_DATA, payload: response.data});
        dispatch({type: LOADING, payload: false});
      })
      .catch((error) => {
        dispatch({type: LOADING, payload: false});
        genericErrorHandler(error)
      })
  }
}

export const modifyOffer = (categoriaId: Number, offerId: Number, coverageId: Number, value: any) => {
  return (dispatch: any) => {
    dispatch({type: LOADING, payload: true});
    axios({
      url: netModifyOffer(categoriaId, offerId, coverageId),
      method: 'patch',
      headers: {
          'accept': '*/*',
          'Content-Type': 'application/json',
      },
      data: `{\"value\": ${value}}`
    })
      .then((response) => {
        dispatch({type: OFFER_DATA, payload: response.data});
        dispatch({type: LOADING, payload: false});
      })
      .catch((error) => {
        dispatch({type: LOADING, payload: false});
        genericErrorHandler(error)
      })
  }
}

export const modifyOfferDate = (categoriaId: number, id: number, data: any) => {
  return (dispatch: any) => {
    dispatch({type: LOADING, payload: true});
    axios({
      url: netModifyOfferDate(categoriaId, id),
      method: 'post',
      headers: {
        'accept': '*/*', 
        'Content-Type': 'application/json',
      },
      data: data
    })
      .then((response) => {
        dispatch({type: OFFER_DATA, payload: response.data});
        dispatch({type: LOADING, payload: false});
      })
      .catch((error) => {
        dispatch({type: LOADING, payload: false});
        genericErrorHandler(error)
      })
  }
}

export const fadeDataIn = (offerData: any) => {
  return (dispatch: any) => {
    let originalData = JSON.parse(JSON.stringify(offerData))
    let fadedData = JSON.parse(JSON.stringify(offerData))
    fadedData.packages[0].coverageGroups.map((a) => {
      a.coverages.map((b) => {
        if(b.unit === "%") {
          b.value = "--"
          b.formattedValue = "--%"
        } else if(b.unit === "$") {
          b.value = "--"
          b.formattedValue = "--"
        }
        return null;
      })
      return null;
    })
    fadedData.packages[0].price.formattedValue = '$--'
    fadedData.endDate = fadedData.startDate
    fadedData.renew.term = "CUSTOM"
    dispatch({type: ORIGINAL_OFFER_DATA, payload: originalData});
    dispatch({type: OFFER_DATA, payload: fadedData});
  }
}

export const fadeDataOut = (offerData) => {
  return (dispatch: any) => {
    let originalData = JSON.parse(JSON.stringify(offerData))
    dispatch({type: OFFER_DATA, payload: originalData});
  }
}

export const getRestrictions = (categoriaId: number, offerId: number, packageId: number, setVerMasAlert: any, setVerMasInfo: any) => {
  return (dispatch: any) => {
    dispatch({type: LOADING, payload: true});
    axios.get(netGetRestrictions(categoriaId, offerId, packageId))
      .then((response) => {
        setVerMasInfo(response.data)
        setVerMasAlert(true)
        dispatch({type: LOADING, payload: false});
      })
      .catch((error) => {
        dispatch({type: LOADING, payload: false});
        genericErrorHandler(error)
      })
  }
}

export const toggleRestrictions = (value: boolean) => {
  return {
      type: SHOW_RESTRICTIONS, 
      payload: value
  };
} 

export const getContactMethods = () => {
  return (dispatch: any) => {
    dispatch({type: LOADING, payload: true});
    axios.get(netGetContactMethods())
      .then((response) => {
        dispatch({type: CONTACT_METHODS, payload: response.data});
        dispatch({type: LOADING, payload: false});
      })
      .catch((error) => {
        dispatch({type: LOADING, payload: false});
        genericErrorHandler(error)
      })
  }
}

export const getContactWhatsapp = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(netGetContactMethodsWhatsapp())
        dispatch({type: CONTACT_WHATSAPP, payload: response.data});
    } catch (error) {
      
    }
  }
}

export const checkOutOffer = (id: any, categoriaId: number, value: number, navigate: any, countryId: number, userId: any, admin: boolean, numeroCotizacion: any) => {
  return (dispatch: any) => {
    dispatch({type: LOADING, payload: true});
    axios({
      url: netSelectPackage(categoriaId, id),
      method: 'put',
      headers: {
          'accept': '*/*',
          'Content-Type': 'application/json',
      },
      data: `{\"value\": ${value}}`
    })
      .then((responseA) => {
        dispatch({type: OFFER_DATA, payload: responseA.data});
        axios({
          url: netCheckOutOffer(),
          method: 'post',
          headers: {
            'accept': '*/*',
            'Content-Type': 'application/json',
          },
          data: `{ \"offerId\": \"${id}\", \"offerHash\": \"${responseA.data.hash}\" }`
        })
          .then((responseB) => {
            debugger
            const adminN = admin ? '&admin=True' : ''
            const numCot = numeroCotizacion ? '&numCot=' + numeroCotizacion : ''
            if(userId) {
              axios({
                url: netCheckOutPolicyHolder(responseB.data.id, userId),
                method: 'put',
                headers: {
                  'accept': '*/*',
                  'Content-Type': 'application/json',
                },
              })
                .then((responseC) => {
                  dispatch({ type: CHECKOUT_DATA, payload: responseC.data });
                  navigate(GetCountryUrl(countryId) + 'checkout?checkoutId=' + responseC.data.id + adminN + numCot, { state: { adminSelectedUser: true } })
                  dispatch({ type: LOADING, payload: false });
                })
                .catch((error) => {
                  dispatch({ type: LOADING, payload: false });
                  genericErrorHandler(error)
                })
            } else {
              navigate(GetCountryUrl(countryId) + 'checkout?checkoutId=' + responseB.data.id + adminN + numCot)
              dispatch({type: CHECKOUT_DATA, payload: responseB.data });
              dispatch({type: LOADING, payload: false});
            }
          })
          .catch((error) => {
            dispatch({type: LOADING, payload: false});
            genericErrorHandler(error)
          })
      })
      .catch((error) => {
        dispatch({type: LOADING, payload: false});
        genericErrorHandler(error)
      })
  }
}