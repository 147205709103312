import React from "react";
import { useNavigate } from "react-router-dom";
import PBOLogo from "../../../assets/images/svgs/logo.svg";
import { socialLinks } from "../../../data";
import "./Footer.css";
import { useMediaQuery } from "react-responsive";
import { GetCountryUrl } from "../../../util/GetCountryUrl";
import { GetGlobalParams } from "../../../util/GetGlobalParams";
import { setRefCategory, setRefQuestion } from "../../../actions";
import { connect } from "react-redux";
import logo_superintendencia from '../../../assets/images/svgs/logo_superintendencia.svg'

let isTabletOrMobile = false;
const Footer = (props: any) => {
  isTabletOrMobile = useMediaQuery({ query: '(max-width: 38.5em)' });
  const navigate = useNavigate();

  return (
    <div className="Footer-container">
      <div className="Footer-container-wrap">
        <div className="Footer-container-wrap-main">
          <div className="Footer-container-wrap-content">
            <div className="Footer-container-wrap-content-links">
              <img src={PBOLogo} alt="pbo logo" className="Footer-container-wrap-content-links-logo" onClick={() => navigate("/")}/>
              <div className="Footer-container-wrap-content-links-list">
                {socialLinks?.map((redSocial,index) => (
                  <a key={index} href={redSocial.link} target="_blank" rel="noreferrer">
                    <img src={redSocial.svg} alt="red social" />
                  </a>
                ))}
              </div>
            </div>
            <div className="Footer-container-wrap-content-section">
              <b className="Footer-container-wrap-content-section-title regular large">Menú</b>
              <b className="Footer-container-wrap-content-section-link regular large" onClick={() => navigate("/")}>Home</b>
              <b 
                className="Footer-container-wrap-content-section-link regular large" 
                onClick={() => {
                  navigate(GetCountryUrl(props.countryId) + 'inicio')
                  props.setRefCategory(true)
                }}
              >Cotizá tu cobertura</b>
              <b 
                className="Footer-container-wrap-content-section-link regular large" 
                onClick={() => {
                  navigate(GetCountryUrl(props.countryId) + 'inicio')
                  props.setRefQuestion(true)
                }}
              >Preguntas frecuentes</b>
              <b className="Footer-container-wrap-content-section-link regular large" onClick={() => navigate("/contacto")}>Contactanos</b>
              <b className="Footer-container-wrap-content-section-link regular large" onClick={() => navigate("/login")}>Ingresar</b>
            </div>
            <div className="Footer-container-wrap-content-section">
              <b className="Footer-container-wrap-content-section-title regular large">Canales de atención</b>
              <b className="Footer-container-wrap-content-section-info regular large">0810-666-0066 / Lunes a Viernes 8 a 20 h</b>
              <b className="Footer-container-wrap-content-section-info regular large">consultas@hipotecarioseguros.com.ar</b>
            </div>
          </div>
          <div className="Footer-container-wrap-info">
            <div className="Footer-container-wrap-info-rights">
              {isTabletOrMobile ?
                <b className="Footer-container-wrap-info-rights-law regular medium">Pardo Salud © 2023 - Todos los derechos reservados</b>
                :
                <b className="Footer-container-wrap-info-rights-law regular small">Pardo Salud © 2023 - Todos los derechos reservados</b>
              }
              <div className="Footer-container-wrap-info-rights-power">
                {isTabletOrMobile ?
                  <>
                    <b className="Footer-container-wrap-info-rights-power-text regular medium">Powered by &nbsp;</b>
                    <b className="Footer-container-wrap-info-rights-power-link regular medium" onClick={() => window.open('https://www.wecover.co', '_blank', "noopener")}>WeCover</b>
                  </>
                  :
                  <>
                    <b className="Footer-container-wrap-info-rights-power-text regular small">Powered by &nbsp;</b>
                    <b className="Footer-container-wrap-info-rights-power-link regular small" onClick={() => window.open('https://www.wecover.co', '_blank', "noopener")}>WeCover</b>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer-container-legals">
        <div className="Footer-container-legals-wrap">
          <p className="Footer-container-legals-title">Razón Social - CUIT PARDO SA 30-66674491-0 | Calle 47 y 22 Colón, Buenos Aires, Argentina.</p>
          <div className="Footer-container-legals-data">
            <div className="Footer-container-legals-data-first">
              <div className="Footer-container-legals-data-first-noColorCont">
                <p className="Footer-container-legals-data-first-noColorCont-noColor">N° de matrícula SSN<br/>301</p>
                <div className="Footer-container-legals-data-first-line"></div>
                <p className="Footer-container-legals-data-first-noColorCont-noColor">Departamento de Orientación<br/>y Asistencia al Asegurado</p>
              </div>
              <div className="Footer-container-legals-data-first-colorCont">
                <p className="Footer-container-legals-data-first-colorCont-color">0800-666-8400</p>
                <div className="Footer-container-legals-data-first-line"></div>
                <p className="Footer-container-legals-data-first-colorCont-color">www.argentina.gob.ar/ssn</p>
              </div>
            </div>
            <div className="Footer-container-legals-data-superImg">
              <img className="Footer-container-legals-data-superImg-img" src={logo_superintendencia}/>
            </div>
          </div>
          <p className="Footer-container-legals-publicidad">Publicidad- Cartera consumo. Oferta vigente del 01/10/23 al 01/04/24. PARDO SA. actuando como agente institorio bajo la RES. Nº 301 SSN ofrece y comercializa seguros de SALUD INTEGRAL, MUJER Y/O SENIOR emitidos por BHN VIDA SA, CUIT 30-69350395-3. Rivadavia 611, piso 10  (C1002AAE) - CABA. TEL 0810-666-0066. <a target="_blank" href="https://hipotecarioseguros.com.ar/">WWW.HIPOTECARIOSEGUROS.COM.AR.</a> Las coberturas están sujetas a los términos y condiciones de póliza.</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
      countryId: state.general.countryId,
  };
};

export default connect(mapStateToProps, {
  setRefCategory,
  setRefQuestion
})(Footer);