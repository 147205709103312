import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux';
import Header from '../../components/generalComponents/header/Header';
import './UserSelect.css'
import Footer from '../../components/generalComponents/footer/Footer';
import { ReactSVG } from 'react-svg'
import Lupa from '../../assets/images/svgs/lupa.svg';
import SadFace from '../../assets/images/svgs/sadFace.svg';
import { 
    actionGetUserListForAdmin,
    actionSetPolicyHolder,
    getOffer,
    checkOutOffer
} from '../../actions';
import MainButton from '../../components/generalComponents/MainButton/MainButton';
import { FormattedMessage } from 'react-intl';
import { USER_DATA } from '../../actions/types';

const UserSelect = (props: any) => {
    const dispatch = useDispatch();
    const [admin, setAdmin] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const [existente, setExistente] = useState<boolean>(true);
    const [nuevo, setNuevo] = useState<boolean>(false);
    const [text, setText] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingOffer, setLoadingOffer] = useState<boolean>(false);
    const [packageId, setPackageId] = useState<any>(null);
    const [timeout, timeoutChange] = useState<any>(0);
    const [userList, setUserList] = useState<any>(null);
    const navigate = useNavigate();

    useEffect(() => {
        handleUrlParams()
    }, [])
    
    const handleUrlParams = () => {
        let admin: any = searchParams.get("admin")
        let offerId: any = searchParams.get("offerId")
        let cId: any = searchParams.get("cId")
        let packageId: any = searchParams.get("packageId")
        if (admin) setAdmin(true)
        if (packageId) setPackageId(packageId)
        if (offerId && cId) props.getOffer(cId, offerId, setLoadingOffer)
    }

    const handleChangeState = (type: string) => {
        setExistente(false)
        setNuevo(false)
        if (type === 'existente') setExistente(true)
        if (type === 'nuevo') {
            setNuevo(true)
            setTimeout(() => {
                props.checkOutOffer(props.offerData?.id, props.offerData?.product?.categoryId, packageId, navigate, props.countryId, null, admin, null)
            }, 750)
        }
    }

    const iconSize = (svg: any) => {
        svg.setAttribute('style', 'height: ' + 23);
        svg.setAttribute('style', 'width: ' + 23);
    }

    const getIcons = () => {
        if(loading) {
            return (
                <div className='userSelect-container-main-inputContainer-loading'></div>
            )
        }
        return (
            <ReactSVG
                src={Lupa}
                beforeInjection={svg => iconSize(svg)}
            />
        )
    }

    const handleSearchTextChange = (text: string) => {
        setText(text)
        setLoading(true)
        if (timeout) timeoutChange(clearTimeout(timeout));
        timeoutChange(setTimeout(() => {
            actionGetUserListForAdmin(text, setLoading, setUserList)
        }, 2000));
    }

    return (
        <div className="userSelect-container">
            {!admin && <Header
                color={'var(--primary500)'}
                secondaryColor={'var(--primary400)'}
            />}
            <div className="userSelect-container-main">
                <div className="userSelect-container-main-selector">
                    <div className="userSelect-container-main-selector-item" onClick={() => handleChangeState('existente')}>
                        <div className="userSelect-container-main-selector-item-circle">
                            <div className="userSelect-container-main-selector-item-circle-inner" style={{transform: existente ? `scale(${1})` : `scale(${0})`}}></div>
                        </div>
                        <p className="userSelect-container-main-selector-item-text"><FormattedMessage id="page.userSelect.existente" defaultMessage="" /></p>
                    </div>
                    <div className="userSelect-container-main-selector-item" style={{marginLeft: 10}} onClick={() => handleChangeState('nuevo')}>
                        <div className="userSelect-container-main-selector-item-circle">
                            <div className="userSelect-container-main-selector-item-circle-inner" style={{transform: nuevo ? `scale(${1})` : `scale(${0})`}}></div>
                        </div>
                        <p className="userSelect-container-main-selector-item-text"><FormattedMessage id="page.userSelect.nuevo" defaultMessage="" /></p>
                    </div>
                </div>
                <div className="userSelect-container-main-inputContainer">
                    <input 
                        id='buscador input'
                        className="userSelect-container-main-inputContainer-input"
                        placeholder='Buscá por nombre y apellido o mail...'
                        onChange={(e: any) => handleSearchTextChange(e.target.value)}
                        value={text}
                    />
                    {getIcons()}
                </div>
                {userList && 
                    <div className="userSelect-container-main-searchContainer">
                        <p className="userSelect-container-main-searchContainer-title"><FormattedMessage id="page.userSelect.resBusqueda" defaultMessage="" /></p>
                        {userList?.map((a: any, index: any) => (
                            <div key={index} className="userSelect-container-main-searchContainer-item">
                                <p className="userSelect-container-main-searchContainer-item-name">{a.name}</p>
                                <div className="userSelect-container-main-searchContainer-item-end">
                                    <p className="userSelect-container-main-searchContainer-item-end-email">{a.username}</p>
                                    <p 
                                        className="userSelect-container-main-searchContainer-item-end-seleccionar"
                                        onClick={() => {
                                            dispatch({ type: USER_DATA, payload: a });
                                            props.checkOutOffer(props.offerData?.id, props.offerData?.product?.categoryId, packageId, navigate, props.countryId, a.id, admin, null)
                                        }}
                                    ><FormattedMessage id="page.userSelect.seleccionar" defaultMessage="" /></p>
                                </div>
                            </div>
                        ))}
                        {userList.length === 0 && 
                            <div className="userSelect-container-main-searchContainer-emptyList">
                                <ReactSVG src={SadFace}/>
                                <p className="userSelect-container-main-searchContainer-emptyList-text"><FormattedMessage id="page.userSelect.noRes" defaultMessage="" /></p>
                                <div className="userSelect-container-main-searchContainer-emptyList-buttonWrapper">
                                    <MainButton
                                        id='Nuevo Usuario Boton'
                                        altStyle2
                                        text='CREA UN NUEVO USUARIO'
                                        onButtonPressed={() => props.checkOutOffer(props.offerData?.id, props.offerData?.product?.categoryId, packageId, navigate, props.countryId, null, admin, null)}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
            {!admin && <Footer/>}
        </div>
    );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    checkOutData: state.checkOut.checkOutData,
    offerData: state.mainCotizar.offerData
  };
};

export default connect(mapStateToProps, {
    actionGetUserListForAdmin,
    actionSetPolicyHolder,
    getOffer,
    checkOutOffer
})(UserSelect);