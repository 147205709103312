import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { IntlProvider } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  changeCountryId,
  channelIdChange,
  toggleErrorOff,
  userAccessTokenSet,
  getUserAuth,
  setAccessTokenAux,
  setGlobalParams,
  getUserData,
  eraseUserData,
  saveDeviceUsed
} from './actions'

import './App.css'

import { GlobalStyles } from "./components/generalComponents/globalStyles/globalStyles.js";

import { countries } from './data';
import { GetGlobalParams } from './util/GetGlobalParams';
import loading from './assets/lotties/loading.json'
import Lottie from 'react-lottie';
import BurgerMenu from './components/generalComponents/BurgerMenu/BurgerMenu';

//PAGES
import Inicio from './pages/inicio/Inicio'
import Cotizar from './pages/cotizar/Cotizar';
import ErrorComponent from './components/generalComponents/ErrorComponent/ErrorComponent';
import RouterInterceptor from './pages/routerInterceptor/RouterInterceptor';
import Cookies from 'universal-cookie';
import ProductSuggestPage from './pages/productSuggestPage/ProductSuggestPage';
import CountrySelector from './pages/countrySelector/CountrySelector';
import Checkout from './pages/checkout/Checkout';
import VerificacionEmail from './pages/verificacionEmail/VerificacionEmail';
import PasswordRecovery from './pages/passwordRecovery/PasswordRecovery'
import PasswordRecoveryCode from './pages/passwordRecoveryCode/PasswordRecoveryCode'
import PasswordRecoveryForm from './pages/passwordRecoveryForm/PasswordRecoveryForm'
import PasswordRecoveryFinish from './pages/passwordRecoveryFinish/PasswordRecoveryFinish'
import Home from './pages/home/Home';
import DetailsPolicy from './pages/detailsPolicy/DetailsPolicy';
import ChangePassword from './pages/changePassword/ChangePassword';
import ChangePasswordSuccess from './pages/changePassword/changePasswordSuccess/ChangePasswordSuccess';
import PerfilHome from './pages/perfilHome/PerfilHome';
import Reclamo from './pages/reclamo/Reclamo';
import MisReclamos from './pages/misReclamos/MisReclamos';
import CheckoutFin from './pages/checkoutFin/CheckoutFin';
import SugeridosPage from './pages/sugeridos/SugeridosPage';
import UserSelect from './pages/userSelect/UserSelect';
import CheckoutFinAdmin from './pages/checkoutFinAdmin/CheckoutFinAdmin';
import Contact from './pages/ssPages/Contact/Contact';
import Moto from './pages/ssPages/Moto/Moto';
import Automovil from './pages/ssPages/Automovil/Automovil';
import Soap from './pages/ssPages/Soap/Soap';
import MotoExito from './pages/ssPages/MotoExito/MotoExito';

//ROUTE TYPES
import ProtectedRoute from './components/routeComponents/ProtectedRoute';
import PrivateRoute from './components/routeComponents/PrivateRoute';
import PublicRoute from './components/routeComponents/PublicRoute';
import RedirectRoute from './components/routeComponents/RedirectRoute';
import AutomovilExito from './pages/ssPages/AutomovilExito/AutomovilExito';
import ContactExito from './pages/ssPages/ContactExito/ContactExito';
import TerminosPage from './pages/TerminosPage/TerminosPage';
import AdminInicio from './pages/adminInicioSesion/AdminInicio';

//REDUX TYPES
import { 
  USER_DATA,
  USER_LOADER
} from './actions/types';
import Oferta from './pages/oferta/Oferta';
import MisCoberturas from './pages/MisCoberturas/MisCoberturas';
import DemoStart from './pages/demoStart/DemoStart';

let cookiesUA = new Cookies();

const App = (props: any) => {
  const dispatch = useDispatch();
  const userLoader = useSelector((state: any) => state.user.userLoader);
  const [lang, setLang] = useState<any>(undefined);
  const [appBaseIframe] = useState<any>(process.env.REACT_APP_PARENT);
  const [appId] = useState<any>(process.env.REACT_APP_ID);
  const [globalFontFamily, setGlobalFontFamily] = useState<any>(process.env.REACT_APP_FONT_FAMILY);

  const sendMessageToParent = () => {
    if(window.location.hostname != 'localhost') {
      window.parent.postMessage('Give', appBaseIframe)
    } return;
  } 

  const recieveMessageFromParent = () => {
    window.addEventListener("message", (event) => {
      if(window.location.hostname != 'localhost') {
        if (event.origin !== appBaseIframe) return;
        props.userAccessTokenSet(event.data);
      } return;
    }, false);
  }

  const generateDeviceUsed = () => {
    const userAgent = window.navigator.userAgent;
    const isMobile = /Mobi/i.test(userAgent);
    const isTablet = /Tablet/i.test(userAgent);
    
    let deviceType;
    if (isMobile) {
      deviceType = 'Mobile';
    } else if (isTablet) {
      deviceType = 'Tablet';
    } else {
      deviceType = 'Desktop';
    }

    props.saveDeviceUsed(deviceType, 'web')

  }

  useEffect(() => {
    sendMessageToParent();
    recieveMessageFromParent();
    generateDeviceUsed();
  }, []) 

  useEffect(() => {
    let cookies = cookiesUA.get('userAccessToken');
    if (cookies) {
      props.userAccessTokenSet(cookies);
      dispatch(getUserAuth());
    } else {
      dispatch({ type: USER_DATA, payload: null });
      dispatch({ type: USER_LOADER, payload: true })
    }
    GetGlobalParams();
  }, []);

  const startCountry = (country: number) => {
    props.channelIdChange(process.env.REACT_APP_ID);
    loadLanguage(country);
    props.changeCountryId(country);
  }

  const changeCountry = (country: number) => {
    props.channelIdChange(process.env.REACT_APP_ID);
    props.userAccessTokenSet(null);
    dispatch({ type: USER_DATA, payload: null });
    dispatch(setAccessTokenAux(null));
    props.changeCountryId(country);
    if (country === undefined) {
      setLang(undefined);
      return;
    }
    loadLanguage(country);
  };

  const changeGlobalParams = (param: string) => {
    props.setGlobalParams(param)
  }

  const loadLanguage = (country: number) => {
    countries.find(c => c.id === country)?.lang().then((messages) => {
      setLang(messages);
    });
  };

  const errorModal = () => {
    if(props.errorMessage) {
      return (
        <div className="coverLoading">
          <ErrorComponent
            errorMessage={props.errorMessage}
            toggleErrorOff={() => props.toggleErrorOff()}
          />
        </div>
      )
    }
  }

  const showLoading = (show: boolean) => {
    if(show) {
      return (    
        <div className="coverLoading">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loading,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
            height={120}
            width={120}
            isStopped={false}
            isPaused={false}
          />
        </div>
      ) 
    } return null;
  }

  const suspenseLoading = () => {
    return showLoading(true);
  }
  const checkLoading = () => {
    return showLoading(props.loading || (props.countryId !== undefined && lang === undefined));
  }

  const setCountry = (country: number) => {
      props.changeCountryId(country);
  }

  const getAllRoutes = () => {
    if (lang === undefined) return <></>;

    return (
      <React.Fragment>
        <Routes>
          <Route path="/inicio" element={<Inicio/>} />
          <Route path="/cotizar" element={<Cotizar/>} />
          <Route path="/oferta" element={<Oferta/>} />
          <Route path="/contacto" element={<Contact/>} />
          <Route path="/contacto-exito" element={<ContactExito/>} />
          <Route path="/moto" element={<Moto/>} />
          <Route path="/moto-exito" element={<MotoExito/>} />
          <Route path="/automovil" element={<Automovil/>} />
          <Route path="/automovil-exito" element={<AutomovilExito/>} />
          <Route path="/soap-seguro" element={<Soap/>} />
          <Route path="/user-select" element={<UserSelect/>} />
          <Route path="/checkout" element={<Checkout/>} />
          <Route path="/checkout-fin" element={<CheckoutFin/>} />
          <Route path="/checkout-fin-admin" element={<CheckoutFinAdmin/>} />
          <Route path="/suggest-product" element={<ProductSuggestPage/>} />
          {appId == 3 && <Route path="/terminos" element={<TerminosPage/>} />}
          <Route element={<PrivateRoute/>}>
            <Route path='/verificacion' element={<VerificacionEmail/>}/>
          </Route>
          <Route element={<PublicRoute/>}>
            <Route path="/login" element={<AdminInicio/>} />
            <Route path="/recovery" element={<PasswordRecovery/>} />
            <Route path="/recovery-code" element={<PasswordRecoveryCode/>} />
            <Route path="/recovery-code-form" element={<PasswordRecoveryForm/>} />
            <Route path="/recovery-code-form-finish" element={<PasswordRecoveryFinish/>} />
          </Route>
          <Route element={<ProtectedRoute/>}>
            <Route path="/home" element={<Home/>} />
            <Route path="/policy/:id" element={<DetailsPolicy/>} />
            <Route path="/change-password" element={<ChangePassword/>} />
            <Route path="/change-password-success" element={<ChangePasswordSuccess/>} />
            <Route path="/perfil" element={<PerfilHome/>} />
            <Route path="/claim_process/:id" element={<Reclamo/>} />
            <Route path="/reclamos" element={<MisReclamos/>} />
            <Route path="/suggest" element={<SugeridosPage/>} />
            <Route path="/mis_coberturas" element={<MisCoberturas/>} />
          </Route>
          <Route path="*" element={<Navigate to={"/"} />}/>
        </Routes>
      </React.Fragment>
    )
  }

  return (
    <IntlProvider locale={"es"} messages={lang} onError={(e) => {}}>
      <GlobalStyles />
      <div style={{ fontFamily: globalFontFamily }}>
        {checkLoading()}
        {errorModal()}
        {userLoader ?
          <Router>
            <React.Fragment>
              <BurgerMenu changeColor={window.location.pathname === '/checkout'} />
              <div
                className="burgerMenu-container-outer"
                style={{ opacity: props.burgerMenuOpen ? 1 : 0, zIndex: props.burgerMenuOpen ? 10000 : -1 }}
              ></div>
              <RouterInterceptor changeCountryId={changeCountry} changeGlobalParams={changeGlobalParams} startCountry={startCountry} setCountry={setCountry} />
              <React.Suspense fallback={suspenseLoading()}>
                <Routes>
                  {!process.env.REACT_APP_ONE_COUNTRY ?
                    <React.Fragment>
                      <Route path="/" element={<CountrySelector/>} />
                      <Route path={"/:" + countries.find(c => c.id === props.countryId)?.url + "/*"} element={getAllRoutes()} />
                      {countries.map((a, index) => (
                        <Route key={index} path={'/' + a.url} element={<RedirectRoute/>}/>
                      ))}
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Route path="/" element={<CountrySelector/>} />
                      <Route path="/*" element={getAllRoutes()} />
                    </React.Fragment>
                  }
                </Routes>
              </React.Suspense>
            </React.Fragment>
          </Router>
        : null}
      </div>
    </IntlProvider>
  );
}

const mapStateToProps = (state: any) => {
  return {
    countryId: state.general.countryId,
    loading: state.general.loading,
    errorMessage: state.general.errorMessage,
    burgerMenuOpen: state.general.burgerMenuOpen,
    userData: state.user.userData,
    globalParams: state.general.globalParams,
    offerData: state.mainCotizar.offerData,
    categorias: state.cotizar.categorias,
    categoriaSelected: state.cotizar.categoriaSelected,
    channelId: state.general.channelId,
    userAccessToken: state.general.userAccessToken
  };
};
 

export default connect(mapStateToProps, {
  changeCountryId,
  channelIdChange,
  toggleErrorOff,
  userAccessTokenSet,
  getUserAuth,
  setGlobalParams,
  getUserData,
  eraseUserData,
  saveDeviceUsed
})(App);